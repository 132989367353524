import React from "react";
import Navigation from "../Navigation/Navigation";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content boxed-content">
        <div className="footer-upper">
          <div className="left">
            <div className="footer-logo-wrapper">
              <img className="footer-logo" src="./images/NHRV_DarkBG.svg" />
              <div className="below-footer">KGR Technologies, LLC</div>
            </div>
            <div className="footer-socials">
                <a href="https://www.instagram.com/nhrvrental" target="_blank">
                  <img src="./images/instagram-icon.svg" />{" "}
                </a>
                <a href="https://x.com/nhrvrental" target="_blank">
                  <img src="./images/x-icon.svg" />{" "}
                </a>
              </div>
          </div>
          <div className="right">
            <a href="tel:603-242-2352" className="phone">
              Call (603) 242-2352
            </a>
            <Navigation footer={true} />
          </div>
        </div>

        <div className="copyright">Copyright 2025 | All rights reserved</div>
      </div>
    </div>
  );
};

export default Footer;
