import React, { Component } from "react";
import UserPaymentInfo from "../UserPaymentInfo/UserPaymentInfo";
import "./PaymentForm.css";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

let price;
let rvName;
let successCallback;
let id;
let start;
let end;
let clientInfo = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};
let options = [];

let googlePay;

class PaymentForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    errorEmail: false,
    errorPhone: false,
    formError: false,
    validMailPhone: false,
  };

  setFirstName = (fn) => {
    this.setState({ firstName: fn, formError: false });
  };

  setLastName = (ln) => {
    this.setState({ lastName: ln, formError: false });
  };

  setPhone = (p) => {
    this.setState({ phone: p, formError: false });
  };

  setEmail = (e) => {
    this.setState({ email: e, formError: false, errorEmail: false });
  };

  checkEmail = () => {
    let error = false;
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!mailRegex.test(this.state.email)) {
      this.setState({ errorEmail: true });
      this.setState({ validMailPhone: false });
      error = true;
    } else {
      this.setState({ errorEmail: false });
      if (!this.state.validMailPhone && this.state.phone != "") {
        this.setState({ validMailPhone: true });
      }
    }
    
    return error;
  };

  checkPhone = () => {
    const phoneRegex =
      /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/im;

    if (!phoneRegex.test(this.state.phone)) {
      this.setState({ errorPhone: true });
      this.setState({ validMailPhone: false });
      return true;
    } else {
      this.setState({ errorPhone: false });
      if (!this.state.validMailPhone && this.state.email != "") {
        this.setState({ validMailPhone: true });
      }
    }

    return false;
  };

  checkForm = () => {
    if (
      this.state.firstName == "" ||
      this.state.lastName == "" ||
      this.state.email == "" ||
      this.state.phone == ""
    ) {
      this.setState({
        formError: true,
      });
      return true;
    }

    return false;
  };

  setPaymentForm = async (priceUpdate = false) => {
    const payments = window.Square.payments(
      "sq0idp-PfQdQZAJ_ZcCkunFKM9RFQ",
      "LYXVMMQDSWF9X"
    );

    const googlePayContainer = document.getElementById("google-pay");

    try {
      if (priceUpdate) {
        const paymentRequest = payments.paymentRequest({
          total: {
            amount: price,
            label: "Total",
          },
          countryCode: "US",
          currencyCode: "USD",
        });

        
        googlePayContainer.innerHTML = "";
        googlePay = await payments.googlePay(paymentRequest);
        await googlePay.attach("#google-pay");
        var buttons = document.getElementById('google-pay').children;
        for (let i = 0; i < buttons.length; i++) {
          if(i != (buttons.length - 1)) {
            buttons[i].remove();
          }
        }
        
        return;
      }
      const card = await payments.card();
      await card.attach("#sq-card");
      const cardPayButton = document.getElementById("pay");
      cardPayButton.addEventListener("click", async () => {
        const result = await card.tokenize();
        this.submit(result.token);
      });

      const paymentRequest = payments.paymentRequest({
        total: {
          amount: price,
          label: "Total",
        },
        countryCode: "US",
        currencyCode: "USD",
      });

      googlePayContainer.innerHTML = "";
      googlePay = await payments.googlePay(paymentRequest);
      await googlePay.attach("#google-pay");
      const googlePayButton = document.getElementById("google-pay");
      googlePayButton.addEventListener("click", async () => {
        if(this.checkPhone() || this.checkEmail()) {
          return;
        }
        if(this.state.firstName == '' || this.state.lastName == '') {
          this.setState({
            formError: true
          })
          return;
        }
        const result = await googlePay.tokenize();
        if(result.details) {
          this.submit(result.token);
        }

      });
    } catch (e) {
      console.log(e);
    }
  };

  submit(token) {
    const idempotency_key = uuidv4();
    clientInfo.firstName = this.state.firstName;
    clientInfo.lastName = this.state.lastName;
    clientInfo.phone = this.state.phone;
    clientInfo.email = this.state.email;
    fetch("https://nhrvrentalapi.nhrvrental.com/process-payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source_id: token,
        idempotency_key: idempotency_key,
        location_id: "LYXVMMQDSWF9X",
        amount: price,
        rvId: id,
        startDate: start,
        endDate: end,
        client: clientInfo,
        options: options,
        rvName: rvName,
      }),
    })
      .catch((err) => {
        alert("Network error: " + err);
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => Promise.reject(errorInfo));
        }
        return response.json();
      })
      .then((data) => {
        successCallback(data.result);
      })
      .catch((err) => {
        successCallback(false);
      });
  }

  /*onGetCardNonce = (event) => {
    if (this.state.errorForm) {
      return;
    }

    if (this.state.errorEmail) {
      return;
    }

    if (this.state.errorPhone) {
      return;
    }

    clientInfo.firstName = this.state.firstName;
    clientInfo.lastName = this.state.lastName;
    clientInfo.phone = this.state.phone;
    clientInfo.email = this.state.email;
    price = this.props.price;
    // Don't submit the form until SqPaymentForm returns with a nonce
    //event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    //this.paymentForm.requestCardNonce();
  };*/

  componentDidMount() {
    this.setPaymentForm();
    price = this.props.price;
    id = this.props.id;
    start = this.props.startDate;
    end = this.props.endDate;
    options = this.props.options;
    successCallback = this.props.success;
    rvName = this.props.rvName;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.price !== this.state.price) {
      price = nextProps.price;
      this.setPaymentForm(true);
    }
  }

  render() {
    return (
      <div id="form-container" className="payment-form">
        <div className="user">
          <UserPaymentInfo
            setFirstName={this.setFirstName}
            setLastName={this.setLastName}
            setEmail={this.setEmail}
            setPhone={this.setPhone}
            checkEmail={this.checkEmail}
            checkPhone={this.checkPhone}
            checkForm={this.checkForm}
          />
          {this.state.errorEmail && (
            <div className="error-msg centered">Invalid email format.</div>
          )}
          {this.state.errorPhone && (
            <div className="error-msg centered">Invalid phone format.</div>
          )}
          {this.state.formError &&
            this.state.validMailPhone && (
              <div className="error-msg centered">All fields are required.</div>
            )}
        </div>
        <div id="sq-card"></div>
        <div id="apple-pay"></div>
        <button
          id="pay"
          className="button-credit-card blue-btn"
          disabled={
            this.state.errorEmail ||
            this.state.errorPhone ||
            this.state.formError ||
            this.state.email == "" ||
            this.state.phone == "" ||
            this.state.firstName == "" ||
            this.state.lastName == "" ||
            !this.state.validMailPhone
              ? true
              : false
          }
        >
          Submit
        </button>
        <div className="google-pay" id="google-pay"></div>
      </div>
    );
  }
}

export default PaymentForm;
